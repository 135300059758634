import React from "react";
import Nav from "../../components/Nav/Nav";
import AboutUsMain from "./Main";
import "../../assets/css/aboutus.css";
import Vision from "./Vision";
import Mission from "./Mission";
import Footer from "../../components/footer/Footer";
import Details from "./Details";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Businesses from "./OurBusinessness";

const Aboutus = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      <Link to="/">
        <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
          Invest Now
        </button>
      </Link>
      <Nav />
      <AboutUsMain />
      <Vision />
      <Mission />
      <Details />
      <Businesses />
      <Footer />
    </div>
  );
};

export default Aboutus;
