import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SwipeInFromRightComponent from "../Animation/SwipeInFromRight";
import { Link } from "react-router-dom";

const SliderHome = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    height: "100vh",
    loop: false,
  };

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };

  return (
    <Slider
      {...settings}
      ref={(slider) => {
        sliderRef = slider;
      }}
    >
      <div>
        <div
          className="home-main"
          style={{ width: "100%", minHeight: "100vh", height: 'auto', position: "relative" }}
        >
          <div className="slider-one">
            <h1 className="home-title">
              We are enabling opportunities for diaspora engagement and local
              partnerships
            </h1>
            <div className="flex-buttons">
              <Link to="/about-us">
                <button className="btn-primary">About us</button>
              </Link>
              <Link to="/investments">
                <button className="btn-secondaryx">Our Investments</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="home-second"
          style={{ width: "100%", minHeight: "100vh", height: 'auto', position: "relative" }}
        >
          <div className="slider-one">
            <h1 className="home-title">
              AgroStrong provides cattle-backed investment opportunities in
              Zimbabwe & South Africa
            </h1>
            <a href="https://agrostrong.net" target="_blank">
              <button className="btn-primary" style={{ paddingLeft: 10 }}>
                Visit Website
              </button>
            </a>

          </div>

        </div>
      </div>
      {/* <div>
        <div
          className="home-fourth"
          style={{ width: "100%", height: "100vh", position: "relative" }}
        >
          <h1 className="home-title">
            21st Century Life Provides funeral cover & services for the South
            African & Zimbabwean markets
          </h1>
          <a href="https://21stcenturylife.co.za" target="_blank">
            <button className="btn-primary" style={{ paddingLeft: 10 }}>
              Visit Website
            </button>
          </a>
        </div>
      </div> */}
      <div>
        <div
          className="home-third"
          style={{ width: "100%", minHeight: "100vh", height: 'auto', position: "relative" }}
        >
          <div className="slider-one">

            <h1 className="home-title">
              Tsigiro Usekelo Assists Africa diaspora in acquiring or constructing
              homes in their homeland
            </h1>
            <a href="https://tsigiro.com" target="_blank">
              <button className="btn-primary" style={{ paddingLeft: 10 }}>
                Visit Website
              </button>
            </a>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default SliderHome;
