import React from 'react'
import { Col, Row } from 'react-bootstrap';
import DKLogo from '../../assets/images/dkwhitelogo.svg';
import locationIcon from '../../assets/images/location.svg';
import phoneIcon from '../../assets/images/phone.svg';
import MapIcon from '../../assets/images/map.svg';
import Facebook from '../../assets/images/facebook.svg';
import Youtube from '../../assets/images/youtube.svg';
import LinkedIn from '../../assets/images/linkedin.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='footer-container'>
            <Row>
                <Col lg="5">
                    <img src={DKLogo} style={{ height: 50 ,marginBottom:30}} />
                </Col>
                <Col lg="3">
                    <h4 style={{ color: '#fff', fontSize: 15, fontWeight: 800 }}>Head Office (South Africa)</h4>
                    <Row style={{ marginTop: 30 }}>
                        <Col lg="2"><img src={locationIcon} style={{ height: 30 }} /></Col>
                        <Col><p style={{ color: '#fff', fontSize: 15 }}>420 Janadel Avenue, Halofway Gardens, Midrand</p></Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col lg="2"><img src={phoneIcon} style={{ height: 30 }} /></Col>
                        <Col><p style={{ color: '#fff', fontSize: 15 }}>+27 (0) 12 285 0017</p></Col>
                    </Row>
                </Col>
                <Col lg="3">
                    <h4 style={{ color: '#fff', fontSize: 15, fontWeight: 800 }}>Our Locations</h4>
                    <img src={MapIcon} style={{ height: 200 }} />
                </Col>
                <Col>
                    <h4 style={{ color: '#fff', fontSize: 15, fontWeight: 800 }}>Socials</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                        <Link to="https://za.linkedin.com/company/diaspora-kapita">
                            <img src={LinkedIn} style={{ height: 20, marginLeft: 10 }} />
                        </Link>
                        <Link to="https://www.facebook.com/diasporakapita/">
                            <img src={Facebook} style={{ height: 20, marginLeft: 30 }} />
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default Footer;