import React, { useState } from "react";
import DKLogo from "../../assets/images/dklogo.svg";
import MenuIcon from "../../assets/images/menu.svg";
import OffcanvasWiddget from "../Offcanva/OffcanvasWiddget";
import { AppRoutes } from "../../App";
import { MenuItem } from "./MenuITem";
import Facebook from "../../assets/images/facebook.svg";
import Youtube from "../../assets/images/youtube.svg";
import LinkedIn from "../../assets/images/linkedin.svg";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="nav-dk">
      <a href="/">
        <img src={DKLogo} className="logo" />
      </a>
      {/* <img src={MenuIcon} onClick={() => setIsOpen(!isOpen)} className='logo-menu' />
            <>
               
                <div className={`menu-container ${isOpen ? 'open' : ''}`}>
                    <div className="menu-item">Home</div>
                    <div className="menu-item">About</div>
                    <div className="menu-item">Services</div>
                    <div className="menu-item">Contact</div>
                </div>
            </> */}
      <OffcanvasWiddget
        actionButton={<img src={MenuIcon} className="logo-menu" />}
      >
        <div style={{ padding: 10, textAlign: "right" }}>
          {AppRoutes.filter((x) => x.name).map((x) => (
            <MenuItem {...x} />
          ))}
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <img src={LinkedIn} style={{ height: 20, marginLeft: 10 }} />
          <img src={Facebook} style={{ height: 20, marginLeft: 30 }} />
          <img src={Youtube} style={{ height: 20, marginLeft: 30 }} />
        </div>
      </OffcanvasWiddget>
    </div>
  );
};

export default Nav;
