import React from 'react';

const GalleryMain = () => {
    return (
        <div className='gallery-container'>
            <div className='inner-gallery-container'>
                <h3>Gallery</h3>
            </div>
        </div>
    )
};

export default GalleryMain;