import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';

const ContactForm = () => {

    const [state, setState] = useState({})

    const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    return (
        <div className='contact-form-page'>
            <>
                <h1 style={{ marginBottom: 30 }}>Leave us a message</h1>
                <Row>
                    <Col lg="4">
                        <input name="name" type="text" onChange={handleInputChange} placeholder='Name*' />
                    </Col>
                    <Col lg="4">
                        <input name="phone" type="text" onChange={handleInputChange} placeholder='Phone*' />
                    </Col>
                    <Col lg="4">
                        <input name="email" type="text" onChange={handleInputChange} placeholder='Email*' />
                    </Col>
                </Row>
                <textarea placeholder='your message*' />
                <button className='btn-primary' style={{ padding: 10 }}>
                    Send Message
                </button>
            </>
        </div>
    )
}

export default ContactForm;