import React from 'react'

const Rule = () => {
    return (
        <div
            className="rule-container"
            style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
        >
            {/* #e3944a */}
            <hr
                className="rule1"
                style={{ backgroundColor: "#000000be", width: 75 }}
            ></hr>
            <hr
                style={{ backgroundColor: "#794a03", width: 75 }}
                className="rule1"
            ></hr>
            <hr
                style={{ backgroundColor: "#ff9900", width: 75 }}
                className="rule1"
            ></hr>
        </div>
    )
}

export default Rule;