import React from 'react';
import TsigiroLogo from '../../assets/images/tsig.png';
import Twenty from '../../assets/images/partner1.png';
import Jambo from '../../assets/images/jamboo.png';
import Aubus from '../../assets/images/aubus.png';
import Mfolozi from '../../assets/images/mfolozi.png';
import Insure from '../../assets/images/insure.png';
import Linar from '../../assets/images/linar.png';
import Agrostrong from '../../assets/images/agrostrong-dk.png';

const DkPortfolio = () => {
    return (
        <div className='capital-structure'>
            <div>
                <p className='title' style={{ color: '#000', textAlign: 'center', fontSize: 25 }}><strong>DIASPORA KAPITA</strong></p>
            </div>
            <div className='rule-container' style={{ justifyContent: 'center', width: '100%', marginBottom: 50 }}>
                {/* #e3944a */}
                <hr className='rule1' style={{ backgroundColor: '#dfb659', width: 150 }}></hr><hr style={{ backgroundColor: '#e3944a', width: 150 }} className='rule1'></hr><hr style={{ backgroundColor: '#47868a', width: 150 }} className='rule1'></hr>
            </div>
            <div className='portfolio-container'>
                <div className='portfolio-card'>
                    <div className='portfolio-title-container'>
                        <h3>Financial Services</h3>
                    </div>
                    <img src={TsigiroLogo} style={{ height: 70 }} />
                    <p>Wealth & Investment Management Platform <br />
                        Insurance <br />
                        Remittances <br />
                    </p>
                    <img src={Twenty} style={{ height: 70 }} />
                    <p>Insurance: Funeral Cover</p>
                    <img src={Mfolozi} style={{ height: 70 }} />
                    <p>Funeral and Repatriation Services</p>
                    <img src={Agrostrong} style={{ height: 70 }} />
                    <p>Cattle backed financial services</p>
                </div>
                <div className='portfolio-card'>
                    <div className='portfolio-title-container'>
                        <h3>Funeral Services</h3>
                    </div>
                    <img src={Twenty} style={{ height: 70 }} />
                    <p>Funeral and Repatriation Services</p>
                    <img src={Mfolozi} style={{ height: 70 }} />
                    <p>Funeral and Repatriation Services</p>
                </div>
                <div className='portfolio-card'>
                    <div className='portfolio-title-container'>
                        <h3>Fintech</h3>
                    </div>
                    <img src={TsigiroLogo} style={{ height: 50 }} />
                    <p>Wealth & Investment Management Platform <br />
                        Insurance <br />
                        Remittances <br />
                    </p>
                    <img src={Insure} style={{ height: 70 }} />
                    <p>InsureTech: Digital Policy Sales</p>
                </div>
                <div className='portfolio-card'>
                    <div className='portfolio-title-container'>
                        <h3>Property</h3>
                    </div>
                    <img src={Aubus} style={{ height: 50 }} />
                    <p>Funeral Parlour Portlofio <br />
                        (South Africa)
                    </p>
                </div>
                <div className='portfolio-card'>
                    <div className='portfolio-title-container'>
                        <h3>Insurance</h3>
                    </div>
                    <img src={Linar} style={{ height: 50 }} />
                    <p>Funeral Parlour Portlofio <br />
                        (South Africa)
                    </p>
                </div>
            </div>
        </div>
    )
};

export default DkPortfolio;