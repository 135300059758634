import React from 'react'
import { Row, Col } from 'react-bootstrap'

const Mission = () => {
    return (
        <Row style={{ height: '50vh', backgroundColor: '#000' }}>
            <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>Our<br></br> Mission</h1>
            </Col>
            <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                <p style={{ color: '#fff', width: '80%', fontSize: 20, marginLeft: 20 }}>
                    To connect the African diaspora with high-impact investment opportunities,
                    fostering economic development and prosperity across the continent.
                </p>
            </Col>
        </Row>
    )
}

export default Mission