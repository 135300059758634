import React from "react";
import TwentyFirstLife from "../../assets/images/21stlife.png";
import AgrostrongLogo from "../../assets/images/agrostrong.png";
import TsigiroUsekelo from "../../assets/images/tsigirologo.png";
import GrowAnimationComponent from "../Animation/GrowComponents";
import { Link } from "react-router-dom";

const InvestmentsSummary = (props) => {
  return (
    <div className="investments-summary">
      {props.show && <h3>Our Investments</h3>}
      <GrowAnimationComponent className="investments-list">
        <div className="investment-item invest-insure">
          <img className="investment-logo" src={TwentyFirstLife} />
          <p className="investment-descr">
            Provides funeral cover & services for the South African & Zimbabwean
            markets. Aims to provide affordable, world-class services to
            Africans from all walks of life.
          </p>
          <div style={{ width: "100%" }}>
            <Link to="https://21stcenturylife.co.za" target="_blank">
              <button className="btn-primary">Visit website</button>
            </Link>
          </div>
        </div>
        <div className="investment-item invest-agro">
          <img className="investment-logo" src={AgrostrongLogo} />
          <p className="investment-descr">
            Maximising the age-old investment that our forefathers have always
            had – cattle! Cattle has always been an asset class that our great-
            grandparents treasured and used to store value and grow wealth.
          </p>
          <div style={{ width: "100%" }}>
            <Link to="https://agrostrong.net" target="_blank">
              <button className="btn-primary">Visit website</button>
            </Link>
          </div>
        </div>
        <div className="investment-item invest-tsig">
          <img className="investment-logo" src={TsigiroUsekelo} />
          <p className="investment-descr">
            Tsigiro Usekelo (TU) platform exist to unlock the opportunities that
            the collaboration between Zimbabweans in diaspora and those back
            home. At the core of Tsigiro Usekelo (TU) is to provide a platform
            for the diaspora to invest and have access to housing back home,
            over, and above helping the diaspora live a financially meaning life
            in the countries that they are based.
          </p>
          <div style={{ width: "100%" }}>
            <Link to="https://tsigiro.com" target="_blank">
              <button className="btn-primary">Visit website</button>
            </Link>
          </div>
        </div>
      </GrowAnimationComponent>
    </div>
  );
};

export default InvestmentsSummary;
