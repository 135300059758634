import React from "react";
import Prop from '../../assets/images/propo.png';

const ValueProposition = () => {
  return (
    <div className="capital-structure">
      <div>
        <div
          className="text-overline"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          THE VALUE PROPOSITION: TSIGIRO-USEKELO PROPERTY FUND
        </div>
        <p
          className="title"
          style={{ color: "#000", textAlign: "center", fontSize: 25 }}
        >
          <strong>A Diaspora Kapita Collective Investment Scheme</strong>
        </p>
      </div>
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <div className='journey-background'>
                <img src={Prop} style={{height:500}} />
            </div>
            <div className="mobile-structure" style={{textAlign:'center'}}>
                <img src={Prop} style={{height:200,width:350}} />
            </div>
    </div>
  );
};

export default ValueProposition;
