import React from 'react'
import { Modal } from 'react-bootstrap';
import CloseButton from '../../assets/images/close.svg';
import { TeamConstant } from './TeamConstant';

const TeamModal = (props) => {

    const member = TeamConstant.filter((x) => x.team === props.team)[0];

    return (
        <Modal
            size='lg'
            show={props.show} onHide={props.onHide} centered style={{ borderRadius: 30, padding: 0 }}  >
            <Modal.Body style={{
                height: '80vh',
                width: 'auto', backgroundColor: '#ffc400', padding: 0,
                display: 'flex'
            }}>
                <img src={CloseButton} style={{ height: 20, float: 'right', cursor: 'pointer', margin: 20, position: 'absolute', right: 0 }} onClick={props.onHide} />
                <div className={"team-img " + props.image}></div>
                <div style={{ padding: 10 ,width:'70%'}}>
                    <h3 style={{marginBottom:0}}>{member?.name}</h3>
                    <p style={{ margin: 0, marginBottom: 20 }}>{member?.title}</p>
                    <h4 >Qualifications</h4>

                    {
                        (member?.qualifications || []).map((x) => (
                            <p style={{margin:0}}>{x}</p>
                        ))
                    }
                    <h4 style={{marginTop:20}}>Background</h4>
                    <p>{member?.description}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TeamModal;