import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NewContent } from '../../Pages/News/NewsContent';
import { Link } from 'react-router-dom';
import SwipeInFromRightComponent from '../Animation/SwipeInFromRight';
import SwipeInFromLeftComponent from '../Animation/SwipeInFromLeft';
import BouncingComponent from '../Animation/BouncingAnimation';

const NewsSummary = () => {
    return (
        <BouncingComponent>
            <div className='newsummary'>
                <h3>Latest news</h3>
                <Row>
                    {
                        NewContent.map(x => (
                            <Col key={x.title} className='news' style={{ width: '100%', padding: 0 }}>
                                <div className={x.className}></div>
                                <p style={{ fontSize: 12, margin: 10 }}>{x.date}</p>
                                <h3 style={{ fontSize: 18, margin: 10, marginBottom: 30 }}>{x.title}</h3>
                                <Link to={"/news/" + x.title}>
                                    <button className='btn-primary'>
                                        Read more
                                    </button>
                                </Link>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </BouncingComponent>
    )
};

export default NewsSummary;