import React from 'react'
import { Row, Col } from 'react-bootstrap'

const Vision = () => {
    return (
        <Row style={{ height: '50vh', backgroundColor: '#ffc400' }}>
            <Col lg="6" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>OUR<br></br> PURPOSE</h1>
            </Col>
            <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                <p style={{ color: '#000', width: '80%', fontSize: 20, marginLeft: 20 }}>
                    To be the premier platform that empowers the African diaspora, in collaboration 
                    with those back home, to invest in and drive sustainable growth across Africa.
                </p>
            </Col>
        </Row>
    )
}

export default Vision