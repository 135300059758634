import React from 'react'
import SwipeInLeftComponent from '../Animation/SwipeInFromLeft';

const InfoSection2 = () => {
    return (
        <div className='info-second'>
            <div className="info-second-container-mobile">
                <div>
                    <p style={{ fontWeight: '600', margin: 0 }}>HOW WE DO THINGS</p>
                    <p className='title-header' style={{fontSize:55}}>Our Investment<br></br>Philosophy</p>
                    <p style={{ marginTop: 10 }}>
                        By investing, developing and empowering employees,
                        companies, economies and nations we operate in through
                        creating wealth for its shareholders and stakeholders.
                        Diaspora Kapita is primarily focused on investing in the
                        following sectors: Mining, Property Development, Agriculture,
                        Funeral Services, Health and Financial Services.</p>
                </div>
            </div>
            <SwipeInLeftComponent className="info-second-container">
                <div className='infosecwidth'>
                    <p style={{ fontWeight: '600', margin: 0 }}>HOW WE DO THINGS</p>
                    <p className='title-header'>Our Investment<br></br>Philosophy</p>
                    <p style={{ marginTop: 10 }}>
                        By investing, developing and empowering employees,
                        companies, economies and nations we operate in through
                        creating wealth for its shareholders and stakeholders.
                        Diaspora Kapita is primarily focused on investing in the
                        following sectors: Mining, Property Development, Agriculture,
                        Funeral Services, Health and Financial Services.</p>
                </div>
            </SwipeInLeftComponent>
        </div>
    )
};

export default InfoSection2;