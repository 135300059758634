import React, { useRef, useEffect, useState } from 'react';

const BouncingComponent = (props) => {
    const [shouldBounce, setShouldBounce] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShouldBounce(true);
                    // Optional: Reset animation by removing the class briefly
                    setTimeout(() => setShouldBounce(false), 10);
                    setTimeout(() => setShouldBounce(true), 20);
                }
            },
            {
                threshold: 0.1, // Adjust as necessary
            }
        );

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, []);

    return (
        <div ref={componentRef} className={`${shouldBounce ? 'bounce' : ''}`}>
            {props.children}
        </div>
    );
};

export default BouncingComponent;