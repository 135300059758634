import React from 'react';
import JourneyIMG from '../../assets/images/journey.png'

function Journey(props) {
    return (
        <div className="capital-structure">
            <div>
            <div
                className="text-overline"
                style={{ textAlign: "center", marginTop: 10 }}
            >
            </div>
                <p
                    className="title"
                    style={{ color: "#000", textAlign: "center", fontSize: 25 }}
                >
                    <strong>OUR JOURNEY THUS FAR</strong>
                </p>
            </div>
            <div className='journey-background'>
                <img src={JourneyIMG} style={{height:600}} />
            </div>
            <div className="mobile-structure" style={{textAlign:'center'}}>
                <img src={JourneyIMG} style={{height:200,width:350}} />
            </div>
        </div>
    );
}

export default Journey;