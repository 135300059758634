import React, { useEffect, useRef, useState } from 'react';

const SwipeInFromLeftComponent = (props) => {
  const componentRef = useRef(null); // Reference to the component
  const [isVisible, setIsVisible] = useState(false); // State to track visibility of the component

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state if intersection changes
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1 // Trigger when 10% of the component is in view
      }
    );

    const currentRef = componentRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [componentRef]);

  // Apply the animation class dynamically based on visibility
  const animationClass = isVisible ? 'swipe-in-left' : '';

  return (
    <div ref={componentRef} className={ `${props.className} ${animationClass}`}>
        {props.children}
    </div>
  );
};

export default SwipeInFromLeftComponent;