import React, { useState } from "react";
import LoveMore from "../../assets/images/Lovemore 2.jpg";
import Vusi from "../../assets/images/Vhusi.jpg";
import Gift from "../../assets/images/Gift.jpg";
import Owen from "../../assets/images/Owen Potani.jpg";
import Winston from "../../assets/images/Winstone Masango  .jpg";
import Alphonce from "../../assets/images/Alphonce Bhunu  .jpg";
import Elliot from "../../assets/images/Elliot.png";
import ViewArrow from "../../assets/images/teamArrow.svg";
import TeamModal from "./TeamModal";
import Munya from "../../assets/images/websiteImages/team/minya.png";
import Bonono from "../../assets/images/websiteImages/team/bonono.png";
import Algna from "../../assets/images/websiteImages/team/algna.png";
import Gina from "../../assets/images/websiteImages/team/gina.png";
import Nocawe from "../../assets/images/websiteImages/team/nocawe.png";
import Beki from "../../assets/images/websiteImages/team/beki.png";
import Fundi from "../../assets/images/fundi.png";
import Cathy from "../../assets/images/cathy.JPG";

const Teams = () => {
  const [state, setState] = useState({ show: false });

  return (
    <>
      <TeamModal
        {...state}
        onHide={() => setState({ ...state, show: false })}
      />
      <div className="teams-container">
        <div className="team-card">
          <img src={Winston} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "winston",
                image: "winston",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Winston Masongo
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Non-Executive Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        {/* 
                
                        ------------
                */}
        <div className="team-card">
          <img src={Alphonce} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "alphonce",
                image: "alphonce",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Alphonce Bhunu
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Non-Executive Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Elliot} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "elliot",
                image: "elliot",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Elliot Zvoushe
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Non-Executive Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
      </div>
      <div className="teams-container">
        <div className="team-card">
          <img src={Bonono} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "bonono",
                image: "bonono",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Bonolo Pelele
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Non-Executive Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>

        <div className="team-card">
          <img src={Owen} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "owen",
                image: "owen",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Owen Potani
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Non-Executive Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={LoveMore} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "Lovemore",
                image: "lv",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Lovemore Tafirenyika Makunike
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Chairmain</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
      </div>
      <div className="teams-container">
        <div className="team-card">
          <img src={Vusi} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "vhusi",
                image: "vhusi",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Vhusi Phiri
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Chief Executive officer</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Gift} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "gift",
                image: "gift",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Gift Chawasarira
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Chief Finance Officer</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Cathy} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "cathy",
                image: "cathy",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                CATHY MUIZA KARANTENGWA
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>
                Events and Services Director
              </p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
      </div>
      <div className="teams-container">
        <div className="team-card">
          <img src={Munya} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "munya",
                image: "munya",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Munyaradzi Makoni
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>
                Chief Technology Officer
              </p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Gina} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "gina",
                image: "gina",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Gina Chikomo
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>CEO Diaspora Platform</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Nocawe} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "nocawe",
                image: "nocawe",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Nocawe Makiwane
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>CEO - Insurance</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
      </div>
      <div className="teams-container">
        <div className="team-card">
          <img src={Beki} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "beki",
                image: "beki",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Bekithemba Nkomo
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>CEO Agrostrong</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Fundi} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "fundi",
                image: "fundi",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                FUNDISWA ZOTE
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Marketing Director</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
        <div className="team-card">
          <img src={Algna} style={{ width: "100%", height: "80%" }} />
          <div
            className="team-descr"
            onClick={() =>
              setState({
                ...state,
                show: true,
                team: "algna",
                image: "algna",
              })
            }
          >
            <section>
              <h5 style={{ fontSize: 13, fontWeight: "800", margin: 0 }}>
                Ignatius Muchenge
              </h5>
              <p style={{ margin: 0, fontSize: 13 }}>Investment Manager</p>
            </section>
            <img src={ViewArrow} style={{ height: 20 }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
