import React from "react";
import FlagIcon from "../../assets/images/flag.svg";
import EmployeeIcon from "../../assets/images/employeeIcon.svg";
import CustomersIcon from "../../assets/images/customersIcon.svg";
import countryIcon from "../../assets/images/countryIcon.svg";
import AnimatedNumber from "./AnimatedNumber";
import Rule from "./Rule";

const Stats = () => {
  return (
    <div style={{height:'auto', minHeight:'100vh'}}>
      <h3 style={{ textAlign: 'center', marginTop: 50, fontSize: 18 }}>INSURANCE</h3>
      <Rule />
      <div className="stats">
        <div className="stat-single">
          {/* <img src={FlagIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={105000} duration={1000} />
          </p>
          <p>Lives under cover</p>
        </div>
        <br></br>
        <div className="stat-single">
          {/* <img src={EmployeeIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            R <AnimatedNumber target={80} duration={1000} />M
          </p>
          <p>Annual Gross Premium</p>
        </div>
        <div className="stat-single">
          {/* <img src={CustomersIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            R <AnimatedNumber target={234} duration={1000} />M
          </p>
          <p>Gross Premium Since 2018</p>
        </div>
        <div className="stat-single">
          {/* <img src={countryIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            R <AnimatedNumber target={138} duration={1000} />M
          </p>
          <p>Claims Processed Since 2018</p>
        </div>
      </div>
      <div className="stats">
        <div className="stat-single">
          {/* <img src={FlagIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={49} duration={1000} />%
          </p>
          <p style={{textAlign:'center'}}>Annual Grow rate of Gross Premium since 2018</p>
        </div>
        <div className="stat-single">
          {/* <img src={EmployeeIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            R <AnimatedNumber target={3.15} duration={1000} />B
          </p>
          <p>Total Cover Sold </p>
        </div>
        <div className="stat-single">
          {/* <img src={CustomersIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={38} duration={1000} />
          </p>
          <p>Branches in South Africa</p>
        </div>
        <div className="stat-single">
          {/* <img src={countryIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={4} duration={1000} />
          </p>
          <p>Provinces Presence</p>
        </div>
      </div>
      <h3 style={{ textAlign: 'center', fontSize: 18,marginTop:50 }}>SAVINGS</h3>
      <Rule />
      <div className="stats">
        <div className="stat-single">
          {/* <img src={FlagIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={20000} duration={1000} />+
          </p>
          <p>Cattle Carrying Capacity</p>
        </div>
        <div className="stat-single">
          {/* <img src={EmployeeIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={14} duration={1000} />%
          </p>
          <p>Annual Average Return</p>
        </div>
        <div className="stat-single">
          {/* <img src={CustomersIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            R<AnimatedNumber target={4.5} duration={1000} />M
          </p>
          <p>Amount Invested</p>
        </div>
        <div className="stat-single">
          {/* <img src={countryIcon} alt="flag-icon" className="icon" /> */}
          <p className="stat-title">
            <AnimatedNumber target={2} duration={1000} />
          </p>
          <p>Countries Present</p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
