import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Nafuico from "../../assets/images/nafuico.png";
import Aubus from "../../assets/images/aubus.png";
import TwentyFirst from "../../assets/images/partner1.png";
import Linar from "../../assets/images/insure.png";
import LinarReal from "../../assets/images/linar.png";
import Mfolozi from "../../assets/images/mfolozi.png";

const NauficoStructure = () => {
  return (
    <div className="capital-structure">
      <div>
        <div
          className="text-overline"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          INSURANCE AND FUNERAL SERVICES
        </div>
        <p
          className="title"
          style={{ color: "#000", textAlign: "center", fontSize: 25 }}
        >
          <strong>NAFUICO STRUCTURE</strong>
        </p>
      </div>
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        {/* #e3944a */}
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <Tree
        lineHeight="30px"
        lineWidth="2px"
        lineColor={"#e3944a"}
        label={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                padding: 10,
                border: "2px solid #e3944a",
                borderRadius: 10,
                fontSize: 12,
              }}
            >
              <img src={Nafuico} style={{ height: 40 }} />
              <br />
              National Funeral Undertakers Investment Company
            </div>
          </div>
        }
      >
        {/* AUBS PRo */}
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>100%</strong>
                <img src={Aubus} style={{ height: 30 }} />
                <strong>Aubus Pro (Pty) Ltd</strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">
                  <p style={{ margin: 0 }}>Property</p>
                </div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">
                    Funeral Parlour <br />
                    Sales Offices
                  </div>
                </div>
              }
            ></TreeNode>
          </TreeNode>
        </TreeNode>
        {/* 21st Century Life */}
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>100%</strong>
                <img src={TwentyFirst} style={{ height: 60 }} />
                <strong>
                  21<sup>st</sup> Century Events Management (Pty) Ltd
                </strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">
                  <p style={{ margin: 0 }}>Funeral Services</p>
                </div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">
                    Local repratriations
                    <br />
                    International repratriations
                    <br />
                    Catering and event Management
                    <br />
                    Caskets and consumables
                    <br />
                  </div>
                </div>
              }
            >
              {/* <TreeNode label={<div>
                                Local repratriations<br />
                                International repratriations<br />
                                Catering and event Management<br />
                                Caskets and consumables<br />
                            </div>} >
                                <TreeNode label={<div>
                                    <a href='https://21stcenturylife.co.za/' style={{ textDecoration: 'none' }} target='_blank'>
                                        <button>
                                            Visit Website
                                        </button>
                                    </a>
                                </div>} />
                                <TreeNode label={<div>
                                    <a href='https://agrostrong.fra1.digitaloceanspaces.com/3.%2021st%20Century%20Life%20%20Funeral%20Services%20Offering.pdf' style={{ textDecoration: 'none' }} target='_blank'>
                                        <button>
                                            Customer Offering
                                        </button>
                                    </a>
                                </div>} />
                            </TreeNode> */}
            </TreeNode>
          </TreeNode>
        </TreeNode>
        {/* 21st Century Life */}
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>50%</strong>
                <img src={Mfolozi} style={{ height: 60 }} />
                <strong>AM Mfolozi</strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">Funeral Cover</div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">Funeral Policy Sales</div>
                </div>
              }
            ></TreeNode>
          </TreeNode>
        </TreeNode>
        {/* 21st Century Life */}
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>100%</strong>
                <img src={TwentyFirst} style={{ height: 60 }} />
                <strong>
                  21<sup>st</sup> Century Life
                </strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">Funeral Cover</div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">Funeral Policy Sales</div>
                </div>
              }
            >
            </TreeNode>
          </TreeNode>
        </TreeNode>
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>100%</strong>
                <img src={Linar} style={{ height: 60 }} />
                <strong>iEnsure Digital (Pty) Ltd</strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">Insure Tech</div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">Digital Policy Sales</div>
                </div>
              }
            ></TreeNode>
          </TreeNode>
        </TreeNode>
        <TreeNode
          label={
            <div className="nafuico-container">
              <div className="nafuico-inner">
                <strong>77%</strong>
                <img src={LinarReal} style={{ height: 50 }} />
                <strong>Linar Life (Pty) Ltd
                </strong>
              </div>
            </div>
          }
        >
          <TreeNode
            label={
              <div className="nafuico-container">
                <div className="nafuico-inner-label">Insurance</div>
              </div>
            }
          >
            <TreeNode
              label={
                <div className="nafuico-container">
                  <div className="nafuico-inner">Insurance <br/>Underwritting</div>
                </div>
              }
            ></TreeNode>
          </TreeNode>
        </TreeNode>
      </Tree>
    </div>
  );
};

export default NauficoStructure;
