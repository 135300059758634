import React from 'react';
import '../../assets/css/team.css';

const TeamMain = () => {
  return (
    <div className='team-container'>
        <div className='team-inner-container'>
           <h3>Board <br></br> and <br/>Management</h3> 
        </div>
    </div>
  )
}

export default TeamMain;