import React from 'react';
import ProblemD from '../../assets/images/problem.png';

function Problem(props) {
    return (
        <div className="capital-structure">
        <div>
        <div
            className="text-overline"
            style={{ textAlign: "center", marginTop: 10 }}
        >
        </div>
            <p
                className="title"
                style={{ color: "#000", textAlign: "center", fontSize: 25 }}
            >
                <strong>THE PROBLEM: INFLATION
                </strong>
            </p>
        </div>
        <div className='journey-background'>
            <img src={ProblemD} style={{height:600}} />
        </div>
        <div className="mobile-structure" style={{textAlign:'center'}}>
            <img src={ProblemD} style={{height:200,width:350}} />
        </div>
    </div>
    );
}

export default Problem;