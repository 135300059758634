import React, { useRef, useEffect, useState } from 'react';

const GrowAnimationComponent = (props) => {
    const [playAnimation, setPlayAnimation] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        let observer;
        if (componentRef.current) {
            observer = new IntersectionObserver(
                ([entry]) => {
                    // When the component enters the viewport, play the animation
                    if (entry.isIntersecting) {
                        setPlayAnimation(true);
                        // Optional: If you want the animation to reset after leaving the viewport, uncomment below
                        // } else {
                        // setPlayAnimation(false);
                    }
                },
                {
                    threshold: 0.1, // Trigger when at least 10% of the component is visible
                }
            );

            observer.observe(componentRef.current);
        }

        return () => {
            if (observer && componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, [componentRef]);

    return (
        <div ref={componentRef} className={`${props.className} ${playAnimation ? 'grow' : ''}`}>
            {props.children}
        </div>
    );
};

export default GrowAnimationComponent;