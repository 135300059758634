import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import { NewContent } from './NewsContent';
import Footer from '../../components/footer/Footer';
import NewsMain from './NewsMain';

const stringToParagraphs = (text, minWordsPerParagraph) => {
  // Split the text into sentences by full stop. The match includes the full stop with the sentence.
  let sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [];

  // Initialize an array to hold the paragraphs
  let paragraphs = [];
  let currentParagraphWords = [];

  // Function to join current words into a paragraph and reset the words list
  const addParagraph = () => {
    if (currentParagraphWords.length > 0) {
      paragraphs.push(`<p>${currentParagraphWords.join(' ')}</p>`);
      currentParagraphWords = []; // Reset for next paragraph
    }
  };

  sentences.forEach(sentence => {
    let words = sentence.split(/\s+/); // Split sentence into words
    Array.prototype.push.apply(currentParagraphWords, words); // Add to current paragraph word list

    // If the current paragraph has exceeded the minimum word count, start a new paragraph
    if (currentParagraphWords.length >= minWordsPerParagraph) {
      addParagraph();
    }
  });

  // Add any remaining words as the last paragraph
  addParagraph();

  // Join the paragraphs with a newline for readability, if needed
  return paragraphs.join('\n');
}

const SingleNews = () => {
  const { newsId } = useParams();

  const x = NewContent.filter((x) => x.title === newsId)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <Nav />
      <NewsMain title={x.title} />

      <div className='news-content'>
        <Link to="/news">
          Back To News
        </Link>
        <br></br>
        <img src={x.Image} style={{ width: '80%', marginTop: 10 }} />
        <br></br>

        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: stringToParagraphs(x.description, 110) }}></div>
      </div>
      <Footer />
    </div>
  )
}

export default SingleNews;