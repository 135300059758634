import React from 'react';
import AgroZim from '../../assets/images/zimopex.png';
import AgroSA from '../../assets/images/saopex.png';

const AgrostrongOperationStructure = () => {
    return (
        <div className="capital-structure">
            <div>
                <div
                    className="text-overline"
                    style={{ textAlign: "center", marginTop: 10 }}
                >
                    Agrostrong
                </div>
                <p
                    className="title"
                    style={{ color: "#000", textAlign: "center", fontSize: 25 }}
                >
                    <strong>AGROSTRONG OPERATING STRUCTURE</strong>
                </p>
            </div>
            <div className="invest-flex">
                <div className="centered">
                    <img src={AgroZim} style={{ width: '100%', minHeight: 500 ,minWidth: 350}} />
                </div>
                <div className="centered">
                    <img src={AgroSA} style={{ width: '100%', minHeight: 500 ,minWidth: 350}} />
                </div>
            </div>
        </div>
    )
}

export default AgrostrongOperationStructure;