import React from 'react'

const AboutUsMain = () => {
    return (
        <div className='about-us-container'>
            <div className='about-us-main'>
                <h3>About us</h3>
            </div>
        </div>
    )
}

export default AboutUsMain;