import React from 'react'

const InvestMain = () => {
    return (
        <div className='invest-main-container'>
            <div className='invest-main-inner'>
                    <h3>Investments</h3>
            </div>
        </div>
    )
}

export default InvestMain;