import React from 'react';
import '../../assets/css/news.css';

const NewsMain = (props) => {
    return (
        <div className='news-main-container'>
            <div className='news-inner-container'>
                <h3>{props.title ? props.title : 'News'}</h3>
            </div>
        </div>
    )
}

export default NewsMain;