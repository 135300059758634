import React from 'react'

const ContactMain = () => {
    return (
        <div className='contact-main'>
            <div className='contact-main-inner'>
                <h3>Get In Touch</h3>
            </div>
        </div>
    )
}

export default ContactMain;