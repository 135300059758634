import React, { useState } from "react";
import Nav from "../../components/Nav/Nav";
import ProgressBar from "@ramonak/react-progress-bar";
import CompanyStructure from "./CompanyStructure";
import News from "../../assets/images/dk.png";
import DkPortfolio from "./DkPortfolio";
import WhyInvest from "./WhyInvest";
import NauficoStructure from "./NafuicoStructure";
import FuneralOpportunity from "./FuneralOpportunity";
import DiasporaOppotunity from "./DiasporaOppotunity";
import { useQuery } from "react-query";
import Fundraiser from "../../api/Fundraiser";
import { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import ValueProposition from "./ValueProposition";
import AgrostrongSection from "./AgrostrongSection";
import AgrostrongOperationStructure from "./AgrostrongOperationStructure";
import AgrostrongProductSuite from "./AgrostrongProductSuite";
import CapitalRequirements from "./CapitalRequirements";
import { Col, Row } from "react-bootstrap";
import Journey from "./Journey";
import AgroJourney from "./AgroJourney";
import Problem from "./Problem";

const encodeBase64 = (data) => {
  if (typeof btoa === "function") {
    return btoa(data);
  } else if (typeof Buffer === "function") {
    return Buffer.from(data, "utf-8").toString("base64");
  } else {
    throw new Error("Failed to determine the platform specific encoder");
  }
};

const InvestNow = () => {
  const [currency, setCurrency] = useState("ZAR");
  const [state, setState] = useState({});
  const [customAmount, setCustomAmount] = useState();
  const [disabled, setDisabled] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [netcashAmountValue, setNetCashAmount] = useState();

  const currencyFixer = (num) => {
    const numberTobeChanged = isNaN(num) ? num : parseInt(num);
    return numberTobeChanged
      ?.toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const currencyFixe2r = (num) => {
    const numberTobeChanged = isNaN(num) ? num : parseInt(num);
    return numberTobeChanged
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const sharePriceCalculator = (amount) => {
    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    return parseInt(amount / sharePrice);
  };

  const transactionId = () => {
    const globalName = (state.fundRaiser?.entity?.entityName || "").replace(
      /\s/g,
      ""
    );

    const stringId = globalName.substring(0, 3).toLowerCase();

    const id = stringId + "-" + (Math.floor(Math.random() * 9000000) + 1000000);

    const encoded = encodeBase64(id);

    return { id, encoded };
  };

  useEffect(() => {
    const id = transactionId();

    setTransaction(id);
  }, []);

  const { error, isLoading } = useQuery(
    "investments",
    Fundraiser.fetchFundraiserEntity,
    {
      onSuccess: (response) => {
        const { data } = response;
        const currency =
          data.fundRaiser?.entity?.entityCountry === "South Africa"
            ? "R"
            : "USD";
        const globalName = (data.fundRaiser?.entity?.entityName || "").replace(
          /\s/g,
          ""
        );
        const stringId = globalName.toUpperCase();
        const id =
          stringId + "-" + (Math.floor(Math.random() * 900000000) + 100000000);
        const encoded = encodeBase64(id);
        setTransaction({ id, encoded });
        setState({ ...data, currency });
        console.log(response);
      },
    }
  );

  const addPercentageToAmount = (amount) => {
    const decimal = state.fundRaiser?.charge
      ? state.fundRaiser?.charge / 100
      : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);
    const trueAmount = parseFloat(amount) + addedAmount;
    return parseFloat(amount) + addedAmount;
  };

  const addPercentageAndTrueAmount = (amount) => {
    const decimal = state.fundRaiser?.charge
      ? state.fundRaiser?.charge / 100
      : 0;
    const addedAmount = parseFloat(amount) * parseFloat(decimal);

    if (state.fundRaiser.entity.entityCountry === "South Africa") {
      return parseFloat(amount) + addedAmount;
    } else {
      const trueAmount = parseFloat(amount) + addedAmount;
      const globalRate = state?.globalSettings?.USDTOZARRATE;
      const convertedAmount = trueAmount * globalRate;
      return convertedAmount;
    }
  };

  const hasReminder = (amount) => {
    if (!amount) {
      return true;
    }

    if (parseInt(amount) <= 0) {
      return true;
    }

    const sharePrice = state.fundRaiser?.entity?.sharePrice || 0;
    const answer = amount % sharePrice === 0;
    setDisabled(answer);
    return true;
  };

  const [realAmount, setRealAmount] = useState(0);
  return (
    <div>
      <Nav />
      <a href="#invest">
        <button
          className="floating-btn"
          style={{ position: "fixed", zIndex: 100, right: 0, top: "50%" }}
        >
          Invest Now
        </button>
      </a>
      <div className="invest-background-container">
        <div className="invest-main">
          <div style={{ marginTop: "25%" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Link to="/our-team" style={{ textDecoration: "none" }}>
                {" "}
                <p
                  style={{
                    color: "#47868a",
                    fontSize: 16,
                    marginBottom: "2%",
                    fontWeight: "800",
                    fontFamily: "Montserrat",
                  }}
                >
                  BOARD & MANAGEMENT
                </p>
              </Link>
              <Link to="/invest-now" style={{ textDecoration: "none" }}>
                {" "}
                <p
                  style={{
                    color: "#47868a",
                    fontSize: 16,
                    marginBottom: "2%",
                    fontWeight: "800",
                    fontFamily: "Montserrat",
                    marginInline: 10,
                  }}
                >
                  | PRIVATE PLACEMENT |
                </p>
              </Link>
              <a href="#contact" style={{ textDecoration: "none" }}>
                {" "}
                <p
                  style={{
                    color: "#47868a",
                    fontSize: 16,
                    marginBottom: "2%",
                    fontWeight: "800",
                    fontFamily: "Montserrat",
                  }}
                >
                  CONTACT DETAILS
                </p>
              </a>
            </div>
            <p className="title" style={{ color: "#000", marginTop: 0 }}>
              Creating value through local and diaspora collaborations
            </p>
            <p>With a permanent workforce of 200 employees and more than 150 sales agents, DK has achieved an impressive track record. Over the past seven years, DK has delivered a 7x return on invested capital and a 5x return on its financial services
                  investments within just five years.</p>               
            <p>In a strategic evolution, DK will soon transition into BridgeFort Capital Limited as part of the process of migrating its listing from the Zimbabwe Stock Exchange (ZSE) to the Victoria Falls Stock Exchange (VFEX). 
            To support this next phase of growth, DK is seeking to raise US$5.5 million.</p>
            <p>In a strategic evolution, DK will soon transition into BridgeFort Capital Limited as part of the process of migrating its listing from the Zimbabwe Stock Exchange (ZSE) to the Victoria Falls Stock Exchange (VFEX). 
                  To support this next phase of growth, DK is seeking to raise US$5.5 million.</p>
            <div
              style={{
                display: "flex",
                marginBottom: 20,
                marginTop: 20,
                justifyContent: "space-between",
              }}
            >
              <section className="invest-target">
                <p style={{ color: "#000", fontSize: 20, margin: 0 }}>
                  Investment Target
                </p>
                <p style={{ color: "#000", fontWeight: "600" }}>
                  {state.currency}{" "}
                  {currencyFixe2r(state.fundRaiser?.fundraisingGoal || 0)}
                </p>
              </section>
              <hr
                style={{ height: 70, backgroundColor: "#000", width: 2 }}
              ></hr>
              <section className="invest-target">
                <ul>
                  <li style={{ fontSize: 15 }}>
                  Pre-money valuation:               US$ 6.8 million
                  </li>
                  <li style={{ fontSize: 15 }}>New Money: US$ 5.5 million</li>
                  <li>Post-Money(Valuation):           US$ 12.3 million
                  </li>
                </ul>
              </section>
            </div>
            <ProgressBar
              className="invest-wrapper"
              completed={state?.percentageProgress}
              customLabel={state?.percentageProgress + "%"}
            />
          </div>
        </div>
      </div>
       {/* <div className='invest-now-container'>
            <Row>
                <Col lg="6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div className='invest-img'></div>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', paddingTop: 40, marginLeft: 20, marginBottom: 50,paddingRight:80 }}>
                  <h3 style={{textAlign:'center',fontSize:50,marginTop:100}}>ABOUT US</h3>
                  <p>
                  Diaspora Kapita (DK) is an investment company established in 2014 in South Africa by a group of 13 Zimbabweans. In 2018, DK expanded its reach by partnering with South African stakeholders in the funeral services industry, which marked its diversification into financial services. Today, DK has a diverse investment portfolio that includes Funeral Services, Insurance, Diaspora Mortgage Lending, and Cattle-Backed Financial Services. </p>
                  <p>With a permanent workforce of 200 employees and more than 150 sales agents, DK has achieved an impressive track record. Over the past seven years, DK has delivered a 7x return on invested capital and a 5x return on its financial services
                  investments within just five years.</p>               
                  <p>In a strategic evolution, DK will soon transition into BridgeFort Capital Limited as part of the process of migrating its listing from the Zimbabwe Stock Exchange (ZSE) to the Victoria Falls Stock Exchange (VFEX). 
                  To support this next phase of growth, DK is seeking to raise US$5.5 million.</p>
                    <ul>
                      <li>Pre-money valuation:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; US$ 6.8 million </li>
                      <li>New Money:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; US$ 5.5 million</li>
                      <li>Post-Money(Valuation):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; US$ 12.3 million </li>
                    </ul>
                </Col>
            </Row>
        </div> */}
      <div className="capital-structure">
        <div>
          <p
            className="title"
            style={{ color: "#000", textAlign: "center", fontSize: 25 }}
          >
            <strong>DK Financial Services : BridgeFort Capital  Limited (Class A) </strong>
          </p>
        </div>
        <div className="structure" style={{paddingBottom:40}}>
          <CompanyStructure />
        </div>
        <div className="mobile-structure">
          <img src={News} style={{height:200}} />
        </div>
      </div>
      <DkPortfolio />
      <WhyInvest />
      <NauficoStructure />
      <FuneralOpportunity />
      <Journey/>
      <DiasporaOppotunity />
      <ValueProposition />
      <Problem />
      <AgrostrongSection />
      <AgroJourney/>
      <AgrostrongOperationStructure />
      <AgrostrongProductSuite />
      <CapitalRequirements />
      {isLoading && (
        <div className="invest-main">
          <span class="loader"></span>
        </div>
      )}
      {!isLoading && state.fundRaiser && (
        <div className="invest-now-section" style={{ paddingTop: 80 }}>
          <div style={{ margin: 50 }}>
            <div
              className="text-overline"
              style={{ textAlign: "center", marginTop: 10 }}
            >
              Get started now!
            </div>
            <p className="title" style={{ color: "#000", textAlign: "center" }}>
              Investment options
            </p>
            <p
              className="title"
              style={{ color: "#000", textAlign: "center", fontSize: 13 }}
            >
              The listing price is US${state.fundRaiser?.entity?.sharePrice} (post VFX Listing) per
              share and online transaction fee of {state.fundRaiser?.charge}%{" "}
            </p>
          </div>
          <div className="grid-container" id="invest">
            {(state.fundRaiser?.investmentOptions || []).map((x, index) => {
              const amount = addPercentageToAmount(x.amount);
              const netcashAmount = addPercentageAndTrueAmount(x.amount);
              return (
                <div className="grid-item" key={index}>
                  <div
                    className="text-overline"
                    style={{
                      textAlign: "center",
                      marginTop: 10,
                      color: "#000",
                      fontWeight: 600,
                    }}
                  >
                    {x?.title}
                  </div>
                  <p
                    className="title"
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginTop: 30,
                      fontSize: 50,
                      textDecoration: "underline",
                      textDecorationColor: "#C0C0C0",
                      textDecorationThickness: 10,
                      fontWeight: "700",
                    }}
                  >
                    {state?.currency}
                    {currencyFixer(addPercentageToAmount(x.amount) || 0)}
                  </p>
                  <p
                    className="desc"
                    style={{ color: "#000", textAlign: "center" }}
                  >
                    You get <strong>{sharePriceCalculator(x.amount)}</strong>{" "}
                    shares in{" "}
                    <strong>{state.fundRaiser?.entity?.entityName}</strong>{" "}
                    (post VFX Listing)
                  </p>
                  <form
                    name="makeP"
                    id="makeP"
                    method="POST"
                    action="https://paynow.netcash.co.za/site/paynow.aspx"
                  >
                    <input
                      type="text"
                      name="m1"
                      value="54580e3d-792b-4d97-8c9b-991f287063fd"
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m2"
                      value="24ade73c-98cf-47b3-99be-cc7b867b3080"
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="p2"
                      value={transaction?.id}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m4"
                      value={transaction?.encoded}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="m5"
                      value={state?.fundRaiser?._id}
                      hidden
                      edit
                    />
                    <input type="text" name="m6" value={x.amount} hidden edit />
                    <input
                      type="text"
                      name="p3"
                      value={state.fundRaiser?.entity?.entityName}
                      hidden
                      edit
                    />
                    <input
                      type="text"
                      name="p4"
                      value={netcashAmount}
                      hidden
                      edit
                    />
                    <button type="submit" className="btn btn-secondary">
                      Invest Now
                    </button>
                  </form>
                </div>
              );
            })}
          </div>
          {!isLoading && state.fundRaiser && (
            <div className="custom-investment">
              <div>
                <div
                  className="text-overline"
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  Get started now!
                </div>
                <p
                  className="title"
                  style={{ color: "#000", textAlign: "center" }}
                >
                  Invest with custom amount
                </p>
              </div>
              <div className="input-container">
                <span style={{ fontSize: 50, fontWeight: "700" }}>US$</span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setCustomAmount(addPercentageToAmount(e.target.value));
                    console.log(e.target.value);
                    setNetCashAmount(
                      addPercentageAndTrueAmount(e.target.value)
                    );
                    hasReminder(e.target.value);
                    setRealAmount(e.target.value);
                  }}
                  min={100}
                />
                <span style={{ fontSize: 50, fontWeight: "700" }}>.00</span>
              </div>
              {customAmount > 0 && (
                <p>
                  You get <strong>{sharePriceCalculator(realAmount)}</strong>{" "}
                  shares in{" "}
                  <strong>{state.fundRaiser?.entity?.entityName}</strong> for{" "}
                  <strong>
                    {" "}
                    (post VFX Listing)
                    {state.currency + " " + currencyFixer(customAmount || 0)}
                  </strong>{" "}
                  including transaction charges{" "}
                </p>
              )}
              <form
                name="makeP"
                id="makeP"
                method="POST"
                action="https://paynow.netcash.co.za/site/paynow.aspx"
              >
                <input
                  type="text"
                  name="m1"
                  value="54580e3d-792b-4d97-8c9b-991f287063fd"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m2"
                  value="24ade73c-98cf-47b3-99be-cc7b867b3080"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="p2"
                  value={transaction?.id}
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m4"
                  value={transaction?.encoded}
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="m5"
                  value={state?.fundRaiser?._id}
                  hidden
                  edit
                />
                <input type="text" name="m6" value={realAmount} hidden edit />
                <input
                  type="text"
                  name="p3"
                  value="Diaspora Kapita"
                  hidden
                  edit
                />
                <input
                  type="text"
                  name="p4"
                  value={netcashAmountValue}
                  hidden
                  edit
                />
                <button
                  type="submit"
                  className="btn btn-secondary"
                  disabled={parseFloat(customAmount) <= 0 || customAmount == ""}
                  style={{
                    marginTop: 10,
                    backgroundColor: !(parseFloat(customAmount) <= 0 || customAmount == "") ? "green" : "#919191",
                  }}
                >
                  Invest Now for {"US$ " + currencyFixer(customAmount || 0)}
                </button>
              </form>
            </div>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default InvestNow;
