import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap';

const OffcanvasWiddget = (props) => {

    const [show, setShow] = useState(false);

    return (
        <>
            <div onClick={() => setShow(true)}>
                {props.actionButton}
            </div>
            <Offcanvas style={{ backgroundColor: '#ffc400' }} show={show} onHide={() => setShow((prevState) => !prevState)} placement="end">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    {props.children}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OffcanvasWiddget;