import React from 'react'
import Footer from '../../components/footer/Footer';
import Nav from '../../components/Nav/Nav';
import '../../assets/css/gallery.css';
import GalleryMain from './GalleryMain';
import GalleryFull from './GalleryFull';
import { Link } from 'react-router-dom';

const Gallery = () => {
    return (
        <div >
            <Link to="/">
                <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
                    Invest Now
                </button>
            </Link>
            <Nav />
            <GalleryMain />
            <GalleryFull />
            <Footer />
        </div>
    )
}

export default Gallery;