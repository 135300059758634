import NewImage1 from '../../assets/images/detailsimg.jpg';
import NewImage2 from '../../assets/images/businesscloseup.jpg';
import ZimbabweanFlag from '../../assets/images/Flag_of_Zimbabwe.gif';
import Sendhome from '../../assets/images/sendhome.jpg';

export const NewContent = [
    {
        title: 'Zimbabwe: Making It Easy for Diaspora to Invest',
        date: 'DECEMBER 1, 2022',
        description: `Zimbabwe’s investment drive under the Second Republic is ensuring that Zimbabweans living outside the country, the diaspora community, receive special attention for several good reasons.

        First of all, they obviously know and care about the country, even if they have made long-term plans to settle elsewhere, and most have fairly close relatives living in Zimbabwe.

        Under the present Constitution they remain citizens of Zimbabwe, even if they have become naturalised somewhere else and carry a foreign passport, so long as they or one of their parents was born in Zimbabwe.

        So they know they have citizen and resident rights in Zimbabwe and this can make it easier, especially when linked with local knowledge and the ability to empower a relative to act for them if necessary.

        One particular problem, and President Mnangagwa touched on this indirectly when he spoke to a diasporan audience on his visit to Dubai for Zimbabwe’s special investment meetings, is that a lot of Zimbabweans living abroad are not ultra-wealthy people able to make big time investments. But a fair number of comfortably off.
 
        So the President was suggesting that some, perhaps with ties to a particular district or area, could combine together and do something worthwhile and useful.
 
        That makes sense, and the suggestion opens a number of other possible routes for the ordinary person living abroad to make a contribution, and fulfil whatever family commitments they have in Zimbabwe at the same time.
 
        Special investment funds could be set up that those in the diaspora could invest in. These would have to be exceptionally well-managed, and we suspect that those looking at investing their money would want well-known private companies or banks who have a totally clean record. But these exist.
 
        It could even be possible, if there was enough demand, to have provincial investment funds, so people could have more direct impact on where they came from or where their parents came from, because we are now starting to see more and more children born in foreign lands as foreign citizens, but who have parents born in Zimbabwe and still have cousins and grandparents living here.
 
        These investment funds might well have advantages over modest direct investments in a particular business. First they would spread the risk. Not every business will succeed, but if you invest in a reasonable number you can predict in advance how many will make it.

        Some of the funds, or some of the money in a fund, might actually go on loans rather than buying a share in a business. A lot of businesses have mentioned that it is difficult to borrow foreign currency in Zimbabwe, because those who have it tend to hoard it rather than use it.
        
        Yet there are plenty of businesses with good accounts and which are highly viable that would be safe bets for a foreign currency loan.
        
        The funds would need to be able to sort their investors according to need. There will be some investors, for example, who simply want a monthly or quarterly return that they could direct towards an aging parent or use to pay school fees for a successive string of young relatives.
 
        Then there might be those who while living and working abroad hope to return one day to Zimbabwe, even if only to retire, and when they come back would like a capital sum or be able to buy a pension or simply be able to have a regular income.

        A well-managed fund should be able to cope with all these needs and demands to the advantage of those outside the country and those inside.

        The other area where we need to sort something out on a more regular basis is the special requirements of some investors.
  
        At the Dubai meeting one Zimbabwean doctor long resident in Australia said he was keen on building a private hospital near the Victoria Falls or Robert Gabriel Mugabe International Airports and President Mnangagwa said if he was serious, then land would be made available.

        But the fact that the question was asked and the President had to personally intervene shows that something more normal and formal is required.

        We now have the Zimbabwe Investment and Development Agency that is there to deal with investment queries, and it does not seem unreasonable that this agency should have a section that deals with queries from the diaspora, that is Zimbabweans living abroad.

        This section could go a bit further with Zimbabweans than perhaps it might do with others, being able to tell them where land is available to buy, or how to apply for State land where the State owns most of the land such as around major airports.

        Of course the modern, united and reformed Zimbabwe Investment and Development Agency does already offer a lot of the services any investor needs, and part of the problem might well be bad memories. Someone who left the country 20 years ago might still be haunted by visions of bureaucrats who do little and politicians who want an envelope stuffed with cash.

        So among other requirements is getting the Second Republic message across that all investors are welcome and obviously a Zimbabwean living outside is especially welcome.

        And that the new policy of making it easy to do business is not a slogan but a detailed policy directive backed by legislation and administrative action, and if anyone wants a “sweetener” then the Zimbabwe Anti-Corruption Commission really want to hear about this.

        The special teams of ministers assembled to accompany the President on his most crucial investment drives can reassure audiences that we mean what we say and outline where investment would be especially welcome, but the nitty gritty of the detailed requirements is probably best handled by the officials who do this for a living and who have been simplifying their bureaucracy to make it a lot easier to meet the essential requirements.
 
        But it remains an excellent idea to involve the Zimbabwean community around the world. Many countries find these informal links extremely useful and a common heritage often provides the elements of trust and communication that really oil the wheels of business, trade and investment.`
        ,
        Image: NewImage1,
        className: 'news-image-1'
    },
    {
        title: "Zimbabwe’s diaspora remittances hit record 1 bln USD",
        date: 'DECEMBER 1, 2022',
        description: `HARARE, July 20 (Xinhua) — Diaspora remittances to Zimbabwe jumped 58 percent in 2020, reaching about 1 billion U.S. dollars, the highest ever contribution made to the local economy.

        Due to the improved remittances inflows, Zimbabwe managed to record a current account surplus, state-run Herald newspaper said on Tuesday.
        
        The development has also helped the country to cover gaps left by trade disruptions caused by the COVID-19 pandemic.
        
        The World Bank, in its latest Zimbabwe Economic Update, said remittances contributed to the country’s resilience to global trade shocks.
        
        “Despite trade disruptions and the sharp decline in global economic activity caused by the pandemic, Zimbabwe’s current account remained in surplus at 5.3 percent in 2020,” said the World Bank.
        
        “A key driver of the surplus was remittances in 2020, which saw a growth of 58 percent. The increase in formal remittances may reflect the shift to greater use of official channels for remittance delivery due to the pandemic,” it said.
        
        Zimbabwe’s Finance Minister Mthuli Ncube recently said diaspora remittances had overtaken foreign aid in the amount of money being contributed to the country’s economy.
        
        Zimbabwe had over the years seen an increase in the number of remittances from the diaspora.
        
        It is estimated that between 2.5 and 3 million Zimbabweans live in other countries, with neighboring South African being the most popular destination.`,
        Image: NewImage2,
        className: 'news-image-2',
    },
    {
        title: 'Here’s Why Zimbabwe Is Ripe For Investment',
        date: 'DECEMBER 1, 2022',
        description: `In recent years, substantial unemployment rates in Zimbabwe have seen many migrate to other nations, including the UK, to work and send funds back to relatives in the country. Last year, the result of this was $1.4 billion in diaspora remittances to Zimbabwe and an economy that operates very differently from many around the globe, particularly those in the West. 

        Nyaradzo Nyimo, Principal at Spear Capital, argues that this creates an attractive opportunity for Western investors, though explains that it needs to be done in the right way.
        
        “Diaspora remittances are a key source of consumer spending in Zimbabwe. This has surged from $1 billion in 2020 to $1.4 billion last year and is on track to surpass this value this year. This presents an attractive opportunity for investors,” says Nyimo.
        
        Nyimo explains that these funds, which come into Zimbabwe from family members living abroad, provide buoyancy to various sectors such as retail, as they support increased consumer spending.
        
        According to World Bank data, the inflow of remittances to sub-Saharan Africa increased by 6.2% to $45 billion in 2021, a surge which Nyimo says has coincided with a decline in foreign investment on the continent. Last year, foreign direct investment to Zimbabwe dropped from $194 million in 2020 to $166 million. 
        
        According to the International Organization for Migration, over 3 million nationals currently live outside of Zimbabwe. As such, the financial boost that the diaspora provides is vital to the local economy.   
        
        Against this background, Spear Capital has recently completed its investment in the food manufacturing business, Associated Foods Zimbabwe Private Limited (AFZ). The business produces a range of popular food products under the Mama’s and Farmgold brands. The provision of basic foods means that AFZ is supporting the needs of Zimbabwe’s large consumer market.
        
        “While we acknowledge that Zimbabwe is a complex market for outside investment, with some even calling this a VUCA (volatile, uncertain, complex and ambiguous) environment, Spear Capital has an experienced team on the ground which understands the market and its challenges,” explains Nyimo. 
        
        “For companies that take the time to understand the market, and to engage with the relevant players on the ground, there are plenty of investment opportunities in Zimbabwe. One can’t ignore the significant contribution of the informal economy, so it is important to be able to tap into this sector as well.” 
        
        “We remain bullish about the geographies and sectors where we invest.”`,
        Image: ZimbabweanFlag,
        className: 'news-image-3',
    },
    {
        title: 'Tsigiro Usekelo – Send Home Partnership',
        date: 'JULY 28, 2023',
        description: `Tsigiro Usekelo is thrilled to announce our partnership with SendHome, an international money remittance company, licenced and regulated by the South African Reserve Bank.
        This collaboration between SendHome and Tsigiro Usekelo will empower our clients, particularly Zimbabweans in the Diaspora, to easily send money back to Zimbabwe, for investments, debt service, family support , among other uses. Clients of Tsigiro Usekelo can conveniently utilize various pay-in points in South Africa, including Pep Stores, Ackermans. Massmart, Flash traders, Kazang, Shoprite Checkers, Pick N Pay, Game, , Flash, Usave and Spar. Alternatively, they can access the Tsigiro Usekelo Online platform (www.tsigiro.com ) from the comfort of their homes to send money.
        Beneficiaries of these remittances will have the option to collect their funds from any CABS and ZB Bank Branch as well as selected Zimpost branches throughout Zimbabwe. To facilitate the onboarding of new clients onto the SendHome or Tsigiro Usekelo platforms, our partnership will leverage the services of our dedicated Tsigiro Usekelo insurance sales agents and 21st Century Life branches across South Africa.
        Vhusi Phiri, CEO of Tsigiro Usekelo, expressed our vision for the platform, aiming to become a comprehensive one-stop solution for the African diaspora, starting with Zimbabweans. Our platform seeks to facilitate investment opportunities both in the home country and the host countries from a single unified platform. Our mission is to enable people to conveniently purchase properties, send money to their families, or invest, all from the comfort of their homes.
        Peterson Tengende, CEO of SendHome, emphasized the importance of remittances as a channel of prosperity for Zimbabweans that allows diasporans to be there when their family and friends need them most. Through synergies in our infrastructure, partnerships like these allow us to achieve significant cost reductions. SendHome guarantees cash collection in US dollars and the fees for sending the money home is a low 6,5%.
        Join us on this remarkable journey as we work together to provide relevant financial products and services for our clients and communities.
        Tsigiro Usekelo is a subsidiary of Diaspora Kapita, an investment company fully owned by the Zimbabwe Diaspora community. Diaspora Kapita (DK) holds diverse investments in financial services, contract mining, agriculture, and construction services. With a workforce of over 200 employees spread across South Africa and Zimbabwe, DK remains committed to fostering growth and development within the region. You can read more about DK on www.diasporakapita.com and Tsigiro on www.tsigiro.com
        Sendhome (www.sendhome.co.za ) is a remittances company, regulated by the South African Reserve Bank. It enables its clients to send money from South Africa, in a quick, secure, and dependable way. With a focus on financial inclusion, Sendhome provides a digital end-to-end process from registration, KYC verification and USD Cash Collection. You can download the Sendhome APP to register and transact.`,
        Image: Sendhome,
        className: 'news-image-4',
    }
]