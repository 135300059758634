import React, { useState, useEffect, useRef } from 'react';

const AnimatedNumber = ({ target, duration = 2000 }) => {
  const [displayedNumber, setDisplayedNumber] = useState(0);
  const ref = useRef(null); // Ref for tracking the element visibility
  
  useEffect(() => {
    let intervalId = null;
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const startTime = Date.now();
        const end = target;
        intervalId = setInterval(() => {
          const elapsedTime = Date.now() - startTime;
          const progress = elapsedTime / duration;

          if (elapsedTime < duration) {
            const currentNumber = Math.round(end * progress);
            setDisplayedNumber(currentNumber);
          } else {
            setDisplayedNumber(end);
            clearInterval(intervalId);
          }
        }, 20);
      } else {
        // Optional: Reset the animation if you want the animation to restart every time it comes into view
        setDisplayedNumber(0);
        if (intervalId) clearInterval(intervalId);
      }
    }, {
      threshold: 0.1 // Adjust this value based on when you want the animation to start
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [target, duration]); // Rerun the effect if the target or duration changes

  return <span ref={ref}>{displayedNumber}</span>;
};

export default AnimatedNumber;