import React from 'react'
import { Row, Col } from 'react-bootstrap';
import locationIcon from '../../assets/images/location.svg';
import phoneIcon from '../../assets/images/phone.svg';
import Facebook from '../../assets/images/facebook.svg';
import Youtube from '../../assets/images/youtube.svg';
import LinkedIn from '../../assets/images/linkedin.svg';

const ContactInfo = () => {

    return (
        <div className='contact-info'>
            <div className='contact-info-inner iframe-holder' style={{height:'auto'}}>
                <Row style={{ marginTop: 30 }}>
                    <Col ><img src={locationIcon} className='contact-logo' style={{ height: 30, width: 30 }} /></Col>
                    <Col ><p style={{ color: '#fff', fontSize: 25 }}>420 Janadel Avenue, Halfway Gardens, Midrand</p></Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                    <Col ><img src={phoneIcon} style={{ height: 30, width: 30 }} /></Col>
                    <Col><p style={{ color: '#fff', fontSize: 25 }}>+27 (0) 12 285 0017</p></Col>
                </Row>
                <div style={{ marginTop: '10%', display: 'flex', flexDirection: 'row' }}>
                    <img src={LinkedIn} style={{ height: 20, marginLeft: 10 }} />
                    <img src={Facebook} style={{ height: 20, marginLeft: 30 }} />
                    <img src={Youtube} style={{ height: 20, marginLeft: 30 }} />
                </div>
            </div>
            <div className='iframe-holder'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229500.03942424414!2d27.81550149453124!3d-26.002844500000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956e0430f546b5%3A0xd87489b176a2157e!2s21st%20Century%20Life%20Funeral%20%26%20Insurance!5e0!3m2!1sen!2szw!4v1713981034563!5m2!1sen!2szw" width="100%" height="100%" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
    
}

export default ContactInfo;