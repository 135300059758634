import * as React from "react";
import { Link } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = (props) => {
  const style = { border: `2px solid #000` };
  const isCurrentPage = () => {
    if (props.name === "Home" && !window.location.href.includes(props.path)) {
      return true;
    }

    return window.location.href.includes(props.path) && props.name !== "Home";
  };
  return (
    <div
      style={{
        width: "100%",
        color: isCurrentPage() ? "#000" : "#fff",
        marginTop: 20,
      }}
    >
      <Link
        to={props.path}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <h1 style={{ textAlign: "right", fontSize: 45 }}> {props.name}</h1>
      </Link>
    </div>
  );
};
