import React from 'react'

const AgrostrongProductSuite = () => {
    return (
        <div className="capital-structure">
            <div>
                <div
                    className="text-overline"
                    style={{ textAlign: "center", marginTop: 10 }}
                >
                    Agrostrong
                </div>
                <p
                    className="title"
                    style={{ color: "#000", textAlign: "center", fontSize: 25 }}
                >
                    <strong>AGROSTRONG PRODUCT SUITE</strong>
                </p>
            </div>
            <div className="invest-flex">
                <div className="centered">
                    <div className='agrostrong-container-top'>
                        CLIENT NEED
                    </div>
                    <div className='agro-info-card' style={{ alignItems: 'center' }}>
                        <p style={{ margin: 0 }}>INSURANCE</p>
                        <p style={{ margin: 0 }}>(Risk Management)</p>
                    </div>
                    <div className='agro-info-card' style={{ alignItems: 'center' }}>
                        <p style={{ margin: 0 }}>Savings & Wealth Creation</p>
                    </div>
                    <div className='agro-info-card' style={{ alignItems: 'center' }}>
                        <p style={{ margin: 0 }}>Loans
                        </p>
                        <p>(Cattle-Backed Loans)</p>
                    </div>
                </div>
                <div className="centered">
                    <div className='agrostrong-container-middle' style={{ minWidth: 250 }}>
                        CLIENT VALUE
                    </div>
                    <div className='agro-info-card-centered' style={{ minWidth: 250 }} >
                        <ol style={{ textAlign: 'left' }}>
                            <li>Funeral Cover (paid out at a value of cow/inkomo) - better funeral cover.</li>
                            <li>Life Plans & Life Cover Products indexed to the value of cattle/inkomo</li>
                            <li>Inflation-indexed product.</li>
                        </ol>
                    </div>
                    <div className='agro-info-card-centered' style={{ minWidth: 250 }} >
                        <ol style={{ textAlign: 'left' }}>
                            <li>Steers - value uplift for each unit is through pen-fattening.</li>
                            <li>Breeders - reproductive assets (heifers and cows) producing calves for investors.</li>
                        </ol>
                        <p>Clients gradually build their capacity to be funded, i.e., use their cattle as savings collateral, unlocking lending and insurance business from the informal market.</p>
                    </div>
                    <div className='agro-info-card-centered' style={{ minWidth: 250 }} >
                        <ol style={{ textAlign: 'left' }}>
                            <li>Micro-Loans</li>
                            <li>Small Business Funding</li>
                        </ol>
                        <p>These will be backed by savings units (cattle) - unlocking the ability to fund the informal market.</p>
                    </div>
                </div>
                <div className="centered">
                    <div className='agrostrong-container-top'>
                        PRODUCT OFFERING
                    </div>
                    <div className='agro-info-card'>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Funeral Cover</li>
                            <li>Life Cover</li>
                            <li>Inkomo Life Plans</li>
                        </ul>
                    </div>
                    <div className='agro-info-card'>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Unit Trust</li>
                            <li>University/College Investment Fund</li>
                        </ul>
                    </div>
                    <div className='agro-info-card'>
                        <ul style={{ textAlign: 'left' }}>
                            <li>Personal Loans</li>
                            <li>Business Loans</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgrostrongProductSuite;