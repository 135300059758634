import React from 'react'
import { NewContent } from './NewsContent';
import { Link } from 'react-router-dom';

const shortenStringToWords = (text, numWords) => {
    // Split the string into an array of words.
    const wordsArray = text.split(' ');

    // Check if the text is shorter or equal to the desired length.
    if (wordsArray.length <= numWords) {
        return text; // No need to shorten or append "..."
    }

    // Slice the array to the desired number of words and join it back into a string.
    const shortened = wordsArray.slice(0, numWords).join(' ');

    return `${shortened}...`;
}

const NewsCards = () => {
    return (
        <div className='news-cards-container'>
            {
                NewContent.map((x, index) => (
                    <div className='news-card' key={index} >
                        <img src={x.Image} style={{ height: 300, width: 300 }} />
                        <div className='news-short-info'>
                            <p>{x.date}</p>
                            <h3>{x.title}</h3>
                            <p>{shortenStringToWords(x.description, 100)}</p>
                            <Link to={'/news/'+x.title}>
                                <button className='btn-primary' style={{ textAlign: 'left', paddingLeft: 20 }} >Read more</button>
                            </Link>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default NewsCards;