export const TeamConstant = [
  {
    team: "Lovemore",
    name: "LOVEMORE MAKUNIKE",
    title: "Chairman",
    qualifications: [
      "MBA",
      "BSc. (Chemistry & Biochemistry",
      "MSc. (Analytical Chemistry)​",
    ],
    description:
      "Lovemore is an experienced investment practitioner with a 33-year track record in enterprise development, trade, and investment across Sub-Saharan countries. Lovemore specializes in industrial development, technology transfer, and business strategy. Skilled in creating sustainable business growth, reducing costs, and assisting startups. Passionate farmer and mentor.",
  },
  {
    team: "vhusi",
    name: "VHUSI PHIRI",
    title: "Chieft Executive Officer",
    qualifications: [
      "Fellow Member of Institute of Bankers",
      "M.Phil in Development Finance- University of Stellenbosch",
      "BBMIT (Hons) Business Management & Information Technology- Catholic University of Zimbabwe.",
      "ABBMIT (Hons)",
      "Programme in Investment Analysis & Portfolio Management – UNISA.",
      "Adv. Dip. in Banking (Retailing of Financial Services)​",
      "Management Dev. Programme",
    ],
    description:
      "Vhusi, an experienced entrepreneur, is the CEO and co-founder of Diaspora Kapita, an investment company involved in various sectors. He leads the Tsigiro Usekelo diaspora platform, connecting the global Zimbabwean Diaspora and fostering economic opportunities. With a background in financial services and technology, Vhusi's innovative approach drives the growth of DK. ",
  },
  {
    team: "gift",
    name: "GIFT CHAWASARIRA",
    title: "CHIEF FINANCIAL OFFICER",
    qualifications: [
      "Chartered Accountant (SA)",
      "B.Com Accounting",
      "CTA",
      "MBL (UNISA)",
    ],
    description:
      "Gift has experience in health administration, short and long-term insurance, Developmental Finance, and Collective Investments management. He sits on the board of Diaspora Kapita and NAFUICO.",
  },
  {
    team: "owen",
    name: "OWEN POTANI",
    title: "Non-Executive Director",
    qualifications: ["Higher Dip. in Marketing Mgnt.​"],
    description:
      "Owen has over 20 years of Operations Management experience. He is a seasoned mining and engineering executive. He has worked at Hilmax as a Contract Manager for Mwana Africa (ASA Resources Group). He also has experience working with OEMs (Sandvik, Atlas Copco & Caterpillar). Owen received training from Euro Power Hydraulics, Sandvik Mining & Construction, and Atlas Copco.",
  },
  {
    team: "winston",
    name: "WINSTONE MASANGO",
    title: "Non-Executive Director",
    qualifications: [
      "M.Eng (Civil Engineering)",
      "B. Eng (Hons)",
      "Arbitration Studies - AASA",
    ],
    description:
      "Winstone serves as the executive director of RLM Consulting Engineers. He brings extensive experience in civil and structural engineering project design, project management, and construction supervision in South Africa, including the Gautrain Rapid Rail Link and Medupi power station. He is a registered professional with the Engineering Council of South Africa (ECSA), a corporate member of the South African Institute of Civil Engineering (SAICE), and an associate member of the Association of Arbitrators Southern Africa.",
  },
  {
    team: "alphonce",
    name: "ALPHONCE BHUNHU",
    title: "Non-Executuve Director",
    qualifications: [
      "BSc. (Comp. Science)",
      "MBL",
      "BBMIT (Hons)",
      "Certified SAP (All-In-One)",
      "Certified SAP (HCM, SFEC)",
    ],
    description:
      "Alphonce is a technology-driven leader with extensive experience in SAP HR, excelling in overseeing Payroll projects globally. His expertise spans various HR components, with successful management of operations in multiple countries. His leadership is denoted by innovative problem-solving and a deep understanding of international payrolls, he is a valuable asset in technology and leadership.",
  },
  {
    team: "elliot",
    name: "ELLIOT ZVOUSHE",
    title: "Non-Executive Director",
    qualifications: [
      "BSc. (Comp. Science)",
      "MBA",
      "MSc. (Telecoms)",
      "MSc. (IT Security Management)",
    ],
    description:
      "An experienced Technology Network Security Planning professional and visionary leader, excelling in planning, deployment, and development. Serial investor focusing on financial inclusion and tech-driven education. Co-founder of Diaspora Kapita, with interests in financial services and mining. Proficient in decision-making, problem-solving, and optimizing emerging technologies. He is adept at creating value through automation and digital tools.",
  },
  {
    team: "munya",
    name: "Munyaradzi Makoni",
    title: "Chief Technology Officer",
    qualifications: ["BSc. (Comp. Science)"],
    description:
      "Munya is a seasoned Enterprise Business Systems Consultant with over 20 years of experience in Enterprise Resource Planning (ERP), Enterprise Asset Management (EAM), and Human Capital Management (HCM) systems spanning the Banking, Manufacturing, Professional Services & Consulting industries.",
  },
  {
    team: "beki",
    name: "Bekithemba Nkomo",
    title: "CEO Agrostrong",
    qualifications: [
      "B.Tech (UZ)",
      "Articles of Clerkship (Ernst & Young)",
      "Business Excellence Assessor (SAEF)",
      "Certified Business Doctor (BDI)",
    ],
    description:
      "Bekithemba has excelled as a values-driven leader, business advisory consultant, mentor and coach. His focus has been corporate finance transactions, business performance improvement, business model re-engineering, business leadership, strategic planning, and execution. He is involved in training in strategy development and implementation as well as in business leadership and his passion is personal and business growth strategies. He has served as Chairman and Non-Executive Director on over 15 boards in Zimbabwe.",
  },
  {
    team: "nocawe",
    name: "Nocawe Makiwane",
    title: "CEO Insurance",
    qualifications: [
      "MBA",
      "Executive Leadership Programme",
      "Honors in Economics",
      "Bachelor Social Sciences",
    ],
    description:
      "Nocawe is a seasoned businesswoman with extensive experience in financial services, fund asset management and insurance. She worked in the Funds Asset Management industry for 15 years under Coronation, Gensec and Stanlib where she was responsible for managing over R 2 billion on behalf of institutional clients in her capacity as a Portfolio Manager and Analyst. She is currently an Executive Director at AM Mfolozi Group Holdings, a Non-Executive Director for EMIRA Listed Property and has previously sat on several boards of companies in various sectors. She also owns a funeral parlour.",
  },
  {
    team: "gina",
    name: "Gina Chikomo",
    title: "CEO Diaspora Platform",
    qualifications: ["MBL (UNISA)", "Fellow (ACCA)", "RPAcc", "BBS (UZ)"],
    description:
      "Gina, an Accountant by trade, has a wealth of experience in prudential fund management. A former MD of ZB Bank Ltd, she has many achievements including being the first and only ever female MD in ZB’s 72-year history. With her notable success within the bank, she has also served as Finance Director of ZB Bank and other roles within the ZB Group. She also serves as a Non-Executive Director at African Sun Ltd and other public interest organizations.",
  },
  {
    team: "algna",
    name: "Ignatius Muchenge",
    title: "Investment Manager",
    qualifications: [
      "CFA Charter-Holder (CFA Institute)",
      "B.Com Banking (Hons) (NUST)",
    ],
    description:
      "Ignatius is an experienced investment professional with expertise in credit portfolio management, transaction advisory services, and banking. He possesses strong leadership, intellectual, and entrepreneurial skills, along with a deep understanding of financial, legal, and accounting principles. He has a proven track record in deal sourcing, analysis, closure, and post-investment governance. He has worked at Grant Thornton and BancABC ZW and is a Non-Executive Director and Board Chairperson at Lincoln Capital.",
  },
  {
    team: "bonono",
    name: "Bonolo Pelele",
    title: "Non-Executive Director",
    qualifications: [
      "BSc (Hons), Actuarial Science",
      "BSc Actuarial & Financial Mathematic",
    ],
    description:
      "Bonolo is a qualified actuary working as a consultant for Xivoni. She has over 7 years of experience in the life insurance industry. This includes actuarial modelling, valuations (SAM, IFRS4 and IFRS17), regulatory reporting, product development, pricing, audit as well as mergers and acquisitions. She has worked with clients in various countries, including countries in the rest of Africa and Europe. She is the secretary of Actuaries Lekgotla, a non-profit organisation whose main aim is to increase the number of qualified black actuaries.",
  },
  {
    team: "fundi",
    name: "FUNDISWA ZOTE",
    title: "Marketing Director",
    qualifications: [
      "Diploma in Travel & Tourism",
      "Diploma in Office Computing",
      "Bachelor of Bus. Administration",
    ],
    description:
      "Fundiswa has experience in Business Development Strategy, IT Solution Advisory, Lifestyle Management and Events Management. She owns a funeral Parlour.",
  },
  {
    team: "cathy",
    name: "CATHY MUIZA KARANTENGWA",
    title: "Events and Services Director",
    qualifications: [
      "Logistics & Supply Chain Management",
      "Business Principles & Practice",
      "International Studies & Psychology",
      ,
      "Language Studies",
    ],
    description:
      "Cathy, a strategic investor and business leader, brings a wealth of experience to the table. Through her holding company, Ruj Investments, she oversees a diversified portfolio across key African sectors. Her investments showcase a keen eye for opportunity, with ventures in manufacturing and a forward-thinking approach through involvement in Adzlok, a scalable mobile advertising solution. Beyond portfolio management, Cathy demonstrates strong leadership qualities. She has a demonstrated ability to build and manage successful businesses.",
  },
];
