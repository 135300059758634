import React from "react";
import Why1 from "../../assets/images/why1.png";
import Why2 from "../../assets/images/why2.png";
import Why3 from "../../assets/images/why3.png";
import One from "../../assets/images/1.png";
import Two from "../../assets/images/2.png";
import Three from "../../assets/images/3.png";

const WhyInvest = () => {
  return (
    <div className="capital-structure">
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        {/* #e3944a */}
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <div className="why-invest-container">
        <p
          style={{
            position: "absolute",
            padding: 10,
            backgroundColor: "#fff",
            marginTop: 20,
            width: "50%",
            textAlign: "center",
            borderRadius: 10,
            fontWeight: 600,
          }}
        >
          INVESTING IN DIASPORA KAPITA OPENS UP THE FOLLOWING OPPORTUNITIES
        </p>
        <div className="why-invest-card">
          <p style={{ marginBottom: 20, fontWeight: "800", fontSize: 13 }}>
            Insurance & Funeral Services
          </p>
          <img src={Why1} style={{ height: 100 }} />
          <p>
            Consolidation of South Africa <br /> funeral cover and services
            industry
          </p>
          <img src={One} style={{ height: 50 }} />
        </div>
        <div className="why-invest-card">
          <p style={{ marginBottom: 20, fontWeight: "800", fontSize: 13 }}>
            Diaspora Mortages and Investments
          </p>
          <img src={Why2} style={{ height: 100 }} />
          <p>
            The Zimbabwe/Africa Diaspora Market <br />
            tapping into Financial Services, Property and more
          </p>
          <img src={Two} style={{ height: 50 }} />
        </div>
        <div className="why-invest-card">
          <p style={{ marginBottom: 20, fontWeight: "800", fontSize: 13 }}>
            Insurance & Funeral Services
          </p>
          <img src={Why3} style={{ height: 100 }} />
          <p>Cattle backed Financial Products</p>
          <img src={Three} style={{ height: 50 }} />
        </div>
      </div>
      <div className="list-container">
        <div
          style={{ width: "50%", marginTop: 50 }}
          className="list-container-inner"
        >
          <ol>
            <li>
              Foreign Currency Operations: Benefit from our robust foreign
              currency business{" "}
            </li>
            <li>
              Expanding Diaspora Clientele: Tap into the growing diaspora market
              for financial services.
            </li>
            <li>
              Growth Potential: Invest in a company poised for significant
              expansion.
            </li>
            <li>
              Scalable Divisions: Our Funeral Services, Africa Diaspora, and
              Cattle-Backed Financial Services in Africa are all primed for
              scalable growth.
            </li>
            <li>Listing on the Victoria Falls Stock Exchange</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WhyInvest;
