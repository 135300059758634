import React from 'react'
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/footer/Footer';
import '../../assets/css/contact.css';
import ContactMain from './ContactMain';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <div>
            <Link to="/invest-now">
                <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
                    Invest Now
                </button>
            </Link>
            <Nav />
            <ContactMain />
            <ContactInfo />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Contact;