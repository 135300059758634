import React from 'react';
import Agrostrong from '../../assets/images/agrostrong-img.png';

const AgrostrongSection = () => {
    return (
        <div className="capital-structure">
            <div>
                <div
                    className="text-overline"
                    style={{ textAlign: "center", marginTop: 10 }}
                >
                    Agrostrong
                </div>
                <p
                    className="title"
                    style={{ color: "#000", textAlign: "center", fontSize: 25 }}
                >
                    <strong>THE CATTLE-BACKED FINANCIAL SERVICES OPPORTUNITY</strong>
                </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <img src={Agrostrong} style={{ width: '70%' }} />
            </div>
        </div>
    )
}

export default AgrostrongSection;