import React from 'react'
import GrowAnimationComponent from '../Animation/GrowComponents';
import { Link } from 'react-router-dom';

const InfoSection1 = () => {
    return (
        <GrowAnimationComponent>
            <div className='info-first'>
                <div className='info-section-a'>

                </div>
                <div className='info-section-b'>
                    <div className='infowidth' ></div>
                    <div >
                        <h2 >About <br></br>Diaspora Kapita</h2>
                        <p >
                            Diaspora Kapita is an investment holding company primarily focused on investing in multiple sectors. Our vision is to
                            be the leading Pan-African investment holding group focused on identifying, acquiring and building a portfolio of sustainable growth assets in Sub-Sahara Africa.
                        </p>
                        <div>
                            <Link style={{textDecoration:'none'}} to="/about-us">
                                <p style={{ color: '#ffc400', cursor: 'pointer' }}>Read more</p>

                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </GrowAnimationComponent>

    )
}

export default InfoSection1;