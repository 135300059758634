import React, { useState } from 'react'
import { galleryConstants } from './GalleryConstants';
import { Gallery } from 'react-grid-gallery';

const GalleryFull = () => {

    const [selectedGallery, setSelectedGallery] = useState(galleryConstants[0])

    return (
        <div className='gallary-full-container'>
            <div className='gallery-title-container'>
                {
                    galleryConstants.map((x, index) => {
                        return <h3 style={{ cursor: 'pointer' }} key={index} onClick={() => setSelectedGallery(x)}
                            className={   (selectedGallery.gallery === x.gallery ? 'selected-title' : '')}
                        >{x.gallery}</h3>
                    })
                }

                {/* <Gallery images={selectedGallery.images} /> */}
            </div>
            {selectedGallery.images}
        </div>
    )
}

export default GalleryFull;