import React from 'react'
import { Col, Row } from 'react-bootstrap';


const Details = () => {
    return (
        <div className='details-container'>
            <Row>
                <Col lg="6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <div className='details-img'></div>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column', paddingTop: 40, marginLeft: 20, marginBottom: 50 }}>
                    <p style={{ width: '90%' }}>Diaspora Kapita is an investment company formed through the collaboration of Africans diaspora and their counterparts back home.  Founded in 2014 by 13 Zimbabwean expatriates, Diaspora Kapita (DK) is more than an investment firm – we are a bridge connecting Zimbabweans around the world with meaningful investment opportunities.
                        Our diverse investments span financial services, insurance, funeral services, agritech, and fintech. Diaspora Kapita employs over 200 people dedicated to servicing our customers.
                        At the heart of DK is our mission to create tangible value not only for our investors but also for customers and client who buy our products. We are dedicated to Africa’s growth,
                        driving job creation and tackling the continent’s most pressing challenges head-on. With the African diaspora remitting over US$48 billion annually and saving US$53 billion more abroad,
                        the opportunity to leverage these funds for transformative change is immense..</p>
                    <p style={{ width: '90%' }}>
                        Zimbabwean Diaspora members are a testament to this potential, with over US$1.5 billion remitted each year and more than US$26 billion over the past 15 years. DK is pivotal in transforming these remittances into impactful investments. Through collaboration between diasporans and local residents in Zimbabwe and South Africa, we create robust co-investment platforms that not only enhance the prosperity of individual investors but also elevate the financial wellbeing of our customers and clients everywhere.
                    </p>
                    <p style={{ width: '90%' }}>
                        Join us at Diaspora Kapita, where your investment goes beyond borders, driving change and fostering prosperity for generations to come.
                    </p>
                    <p >To get involved, simply head over to <a href='https://tsigiro.com' target='_blank'> www.tsigiro.com</a> </p>
                </Col>
            </Row>
        </div>
    )
}

export default Details;