import React from 'react';
import CapitalA from '../../assets/images/cap1.png';
import CapitalB from '../../assets/images/cap2.png';
import CapitalC from '../../assets/images/cap3.png';
import CapitalD from '../../assets/images/cap4.png';

const CapitalRequirements = () => {
    return (
    <div className="capital-structure">
        <div>
            <div
                className="text-overline"
                style={{ textAlign: "center", marginTop: 10 }}
            >
                DK
            </div>
            <p
                className="title"
                style={{ color: "#000", textAlign: "center", fontSize: 25 }}
            >
                <strong>DIASPORA KAPITA CAPITAL REQUIREMENTS</strong>
            </p>
        </div>
        <h3 style={{textAlign:'center',padding:50}}>Diaspora Kapita requires US$ 5.5 million to see its vision through, and this capital requirement will be raised in 2 tranches:
        </h3>
        <div className="invest-flex">
            <div className="centered" >
                <img src={CapitalA} style={{ width: '100%', marginTop: 50 ,minHeight:250,minWidth:350}} />
            </div>
            <div className="centered">
                <img src={CapitalB} style={{ width: '100%', marginTop: 50 ,minHeight:250,minWidth:350}} />
            </div>
        </div>
        <div>
            <p
                className="title"
                style={{ color: "#000", textAlign: "center", fontSize: 25 }}
            >
                <strong>How do I Invest ?</strong>
                <div className="invest-flex">
                    <div className="centered" >
                        <img src={CapitalC} style={{ width: '100%', marginTop: 50 ,height:200,minWidth:350}} />
                    </div>
                    <div className="centered">
                        <img src={CapitalD} style={{ width: '100%', marginTop: 50 ,minHeight:250,minWidth:350}} />
                    </div>
                </div>
            </p>
        </div>
    </div>
    )
}

export default CapitalRequirements;