import React from "react";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/footer/Footer";
import InvestMain from "./InvestMain";
import InvestmentCards from "./InvestmentCards";
import InvestmentsSummary from "../../components/OurInvestments/InvestmentsSummary";
import { Link } from "react-router-dom";

const Investments = () => {
  return (
    <div>
      <Link to="/">
        <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
          Invest Now
        </button>
      </Link>
      <Nav />
      <InvestMain />
      <InvestmentsSummary />
      <Footer />
    </div>
  );
};

export default Investments;
