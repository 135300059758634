import React from 'react'
import { Row, Col } from 'react-bootstrap'

const Businesses = () => {
    return (
        <>
            <Row style={{ height: '50vh', backgroundColor: '#ffc400' }}>
                <Col lg="6" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>INSURANCE</h1>
                </Col>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                    <p style={{ color: '#000', width: '80%', fontSize: 20, marginLeft: 20 }}>
                        We provide funeral insurance cover to the retail mass market through more than 38 branches in South Africa.
                        Since launching our insurance business in 2018, we have collected R225 million in gross premiums and paid
                        over R101 million in claims as of December 2023.
                    </p>
                </Col>
            </Row>
            <Row style={{ height: '50vh', backgroundColor: '#000' }}>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>
                        Funeral
                        <br></br> Services</h1>
                </Col>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                    <p style={{ color: '#fff', width: '80%', fontSize: 20, marginLeft: 20 }}>
                        We offer funeral services under 21st Century Life and Mfolozi Funeral Services. Operating mainly in four provinces of South Africa,
                        we also provide funeral repatriation services to SADC countries.
                    </p>
                </Col>
            </Row>
            <Row style={{ height: '50vh', backgroundColor: '#ffc400' }}>
                <Col lg="6" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>Savings  &<br></br> Investments</h1>
                </Col>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                    <p style={{ color: '#000', width: '80%', fontSize: 20, marginLeft: 20 }}>
                        We operate in the alternative investment space, introducing cattle-backed financial services through AgroStrong.
                    </p>
                </Col>
            </Row>
            <Row style={{ height: '50vh', backgroundColor: '#000' }}>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>
                        Lending
                    </h1>
                </Col>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                    <p style={{ color: '#fff', width: '80%', fontSize: 20, marginLeft: 20 }}>
                        We are developing mortgage facilities for the diaspora community, enabling them to acquire or build properties back home.
                    </p>
                </Col>
            </Row>
            <Row style={{ height: '50vh', backgroundColor: '#ffc400' }}>
                <Col lg="6" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <h1 style={{ fontSize: 85, fontWeight: '700', color: '#fff', textAlign: 'right', lineHeight: '80px', marginRight: 20 }}>FinTech</h1>
                </Col>
                <Col style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', }}>
                    <p style={{ color: '#000', width: '80%', fontSize: 20, marginLeft: 20 }}>
                        Our digital platform, Tsigiro Usekelo, makes all our financial services easily accessible.
                    </p>
                </Col>
            </Row>
        </>
    )
};

export default Businesses