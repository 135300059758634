import React from 'react'
import Footer from '../../components/footer/Footer';
import Nav from '../../components/Nav/Nav';
import NewsMain from './NewsMain';
import NewsCards from './NewsCards';
import { Link } from 'react-router-dom';

const News = () => {
    return (
        <div>
            <Link to="/">
                <button className='floating-btn' style={{ position: 'fixed', zIndex: 100, right: 0, top: '50%' }} >
                    Invest Now
                </button>
            </Link>
            <Nav />
            <NewsMain />
            <NewsCards />
            <Footer />
        </div>
    )
}

export default News;