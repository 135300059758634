import React from "react";
import Strat from "../../assets/images/Strat.png";

const FuneralOpportunity = () => {
  return (
    <div className="capital-structure">
      <div>
        <div
          className="text-overline"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          INSURANCE AND FUNERAL SERVICES
        </div>
        <p
          className="title"
          style={{ color: "#000", textAlign: "center", fontSize: 25 }}
        >
          <strong>FUNERAL SERVICES & FUNERAL INSURANCE OPPORTUNITY</strong>
        </p>
      </div>
      <div
        className="rule-container"
        style={{ justifyContent: "center", width: "100%", marginBottom: 50 }}
      >
        <hr
          className="rule1"
          style={{ backgroundColor: "#dfb659", width: 150 }}
        ></hr>
        <hr
          style={{ backgroundColor: "#e3944a", width: 150 }}
          className="rule1"
        ></hr>
        <hr
          style={{ backgroundColor: "#47868a", width: 150 }}
          className="rule1"
        ></hr>
      </div>
      <div className="invest-now-section" style={{ paddingTop: 80 }}>
        <div className="grid-container">
          <div className="grid-item" style={{ justifyContent: "flex-start" }}>
            <p
              className="smt"
              style={{ color: "#000", fontSize: 20, fontWeight: "500" }}
            >
              1. The Opportunity
            </p>
            <ul style={{ color: "#000", marginTop: 40, fontSize: 13 }}>
              <li>
                US$ 1.86 billion Annual Gross Written Premium for Funeral Cover
                in South Africa (Underwriting).
              </li>
              <li>US$ 580 million funeral services industry - South Africa.</li>
              <li>14.7 million funeral policies in South Africa.</li>
              <li>1.9 million policies in Zimbabwe.</li>
              <li>510,000 deaths per year in South Africa.</li>
              <li>130,000 deaths per year in Zimbabwe.</li>
            </ul>
          </div>
          <div className="grid-item" style={{ justifyContent: "flex-start" }}>
            <p
              className="smt"
              style={{ color: "#000", fontSize: 20, fontWeight: "500" }}
            >
              2. Our Ambitions
            </p>
            <div className="grid-flex" style={{ marginTop: 30 }}>
              <section className="square-chat">
                <p className="des" style={{ margin: 0, fontWeight: "600" }}>
                  VISION
                </p>
                <p className="des" style={{ margin: 0, fontWeight: "600" }}>
                  2029
                </p>
              </section>
              <section className="list-section">
                <ul style={{ margin: 0, fontSize: 13, color: '#000' }}>
                  <li style={{color:'#000'}}>0.5 million lives under cover .</li>
                  <li style={{color:'#000'}}>US$ 24 million Annual Gross Premium </li>
                  <li style={{color:'#000'}}>Annual Funeral Services Income of US$ 9.6 million</li>
                  <li style={{color:'#000'}}>Nationwide presence in South Africa.</li>
                </ul>
              </section>
            </div>
          </div>
          <div className="grid-item" style={{ justifyContent: "flex-start" }}>
            <p
              className="smt"
              style={{ color: "#000", fontSize: 20, fontWeight: "500" }}
            >
              3. Our Journey: Track Record
            </p>
            <ul style={{ color: "#000", fontSize: 13, marginTop: 40 }}>
              <li>Started operations in 2018.</li>
              <li>
                Annual Gross Premium (US$ 3.8 million) achieved within 4 years
              </li>
              <li>
                Accessed US$ 3.7 million funding from listed financial services
                group.
                <ul>
                  <li>1st Draw-down: US$ 1.6 million - August 2022</li>
                  <li>2nd Draw-down: US$ 1.1 million - June 2023</li>
                </ul>
              </li>
              <li>Presence in 4 provinces in South Africa, with 38 Branches</li>
              <li>More than 104,000 lives under cover</li>
            </ul>
          </div>
          <div className="grid-item" style={{ justifyContent: "flex-start" }}>
            <p
              className="smt"
              style={{ color: "#000", fontSize: 20, fontWeight: "500" }}
            >
              4. Our Strategy
            </p>
            <img src={Strat} style={{ height: 250 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuneralOpportunity;
